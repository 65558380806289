import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    Typography,
    List,
    ListItem,
    useTheme,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { tokens } from '../../theme';
import AvatarManager from '../people/AvatarManager';
import useMediaQuery from '@mui/material/useMediaQuery';

const ViewTeamModal = ({
    isViewModalOpen,
    setViewModalOpen,
    selectedTeamPersons,
    setSelectedTeamPersons,
    selectedTeamName,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        setViewModalOpen(false);
        setSelectedTeamPersons([]);
    };

    return (
        <Modal
            open={isViewModalOpen}
            onClose={handleClose}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '90%' : '30%',
                    height: isMobile ? '60%' : '70%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Close Icon */}
                <Box
                    sx={{
                        height: '7%',
                        width: '98%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Title */}
                <Box
                    sx={{
                        height: '8%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant={isMobile ? 'h4' : 'h3'}
                        color={colors.grey[100]}
                        sx={{
                            textDecoration: 'underline',
                            marginBottom: '8px',
                        }}
                    >
                        {selectedTeamName}
                    </Typography>
                </Box>

                {/* Scrollable List */}
                <Box
                    sx={{
                        height: '70%',
                        width: '90%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                        boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                        margin: '0 auto',
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                    }}
                >
                    <List>
                        {selectedTeamPersons.map((person, index) => (
                            <ListItem key={index}>
                                <AvatarManager
                                    personId={person.id}
                                    username={person.username}
                                />
                                <Typography
                                    variant={isMobile ? 'body1' : 'h6'}
                                    color={colors.grey[100]}
                                    ml={1}
                                >
                                    {person.username}: {person.firstName}{' '}
                                    {person.lastName}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>

                {/* Close Button */}
                <Box
                    sx={{
                        height: '10%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        bottom: 0, // Nail it to the bottom
                        paddingBottom: 2,
                    }}
                >
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        sx={{
                            backgroundColor: colors.redAccent[500],
                            '&:hover': {
                                backgroundColor: colors.redAccent[700],
                            },
                            whiteSpace: 'nowrap', // Prevents text wrapping
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewTeamModal;
