import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
    FormControl,
    useMediaQuery,
    Pagination,
} from '@mui/material';
import { tokens } from '../../theme';

const CustomFooter = ({
    rowCount,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    backgroundColor,
    buttons,
    onActionSelect, // Function passed to handle select menu actions
    row = null, // Optional prop
    invoice = null, // Optional prop
    isOuterTable = false, // Prop to determine if it's the outermost table
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1460);
    const [selectedPageSize, setSelectedPageSize] = useState(rowsPerPage);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1460);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleChangePageSize = (e) => {
        const newSize = parseInt(e.target.value, 10);
        setSelectedPageSize(newSize);
        onRowsPerPageChange(e);
    };

    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        if (typeof onActionSelect === 'function') {
            onActionSelect(action, row, invoice); // Pass row and invoice if they exist
        }

        setSelectedAction(''); // Reset the select menu
    };

    const firstRowIndex = page * selectedPageSize + 1;
    const lastRowIndex =
        (page + 1) * selectedPageSize > rowCount
            ? rowCount
            : (page + 1) * selectedPageSize;

    return (
        <Box
            display="flex"
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
            p={isMobile ? 1 : 2}
            backgroundColor={backgroundColor || colors.blueAccent[700]}
            height={isMobile ? 'auto' : '56px'}
            borderRadius="0 0 3px 3px"
            flexDirection={isMobile ? 'column' : 'row'}
        >
            {isMobile && isOuterTable ? (
                <>
                    {/* Use the Select menu for the outermost table on mobile */}
                    <FormControl fullWidth sx={{ mb: 2 }} size="small">
                        <Select
                            value={selectedAction}
                            onChange={handleSelectAction}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Select action' }}
                            style={{
                                border: '1px solid rgba(255, 255, 255, 0.23)',
                                color: 'inherit',
                                borderRadius: theme.shape.borderRadius,
                                padding: theme.spacing(0.5),
                            }}
                        >
                            <MenuItem value="" disabled>
                                Select an action
                            </MenuItem>
                            {buttons.map((button, index) => (
                                <MenuItem key={index} value={button.label}>
                                    {button.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <Typography variant="h6" color={colors.grey[100]}>
                            Rows per page:
                        </Typography>
                        <FormControl
                            sx={{
                                m: 1,
                                minWidth: '80px',
                            }}
                            size="small"
                        >
                            <Select
                                value={selectedPageSize}
                                onChange={handleChangePageSize}
                                style={{
                                    backgroundColor:
                                        selectedPageSize === ''
                                            ? colors.grey[500]
                                            : 'inherit',
                                    border: '1px solid rgba(255, 255, 255, 0.23)',
                                    color: 'inherit',
                                    borderRadius: theme.shape.borderRadius,
                                    padding: theme.spacing(0.5),
                                }}
                            >
                                {[10, 25, 50].map((pageSizeOption) => (
                                    <MenuItem
                                        key={pageSizeOption}
                                        value={pageSizeOption}
                                    >
                                        {pageSizeOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="h6" color={colors.grey[100]} ml={1}>
                            {`${firstRowIndex}-${lastRowIndex} of ${rowCount}`}
                        </Typography>
                        <Pagination
                            color="primary"
                            count={Math.ceil(rowCount / selectedPageSize)}
                            page={page + 1}
                            onChange={(event, newPage) =>
                                onPageChange(newPage - 1)
                            }
                            sx={{
                                '.Mui-selected': {
                                    backgroundColor: colors.blueAccent[900],
                                    color: colors.grey[100],
                                },
                                '& .MuiPaginationItem-root:hover': {
                                    backgroundColor: colors.blueAccent[800],
                                },
                            }}
                        />
                    </Box>
                </>
            ) : (
                <>
                    {/* Use buttons in inner tables on mobile or in all tables on non-mobile */}
                    <Box display="flex" alignItems="center">
                        {buttons.map((button, index) => (
                            <Button
                                key={index}
                                type="button"
                                variant="contained"
                                onClick={button.onClick}
                                sx={button.styles}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" color={colors.grey[100]}>
                            Rows per page:
                        </Typography>
                        <FormControl
                            sx={{
                                m: 1,
                                minWidth: '80px',
                            }}
                            size="small"
                        >
                            <Select
                                value={selectedPageSize}
                                onChange={handleChangePageSize}
                                style={{
                                    backgroundColor:
                                        selectedPageSize === ''
                                            ? colors.grey[500]
                                            : 'inherit',
                                    border: '1px solid rgba(255, 255, 255, 0.23)',
                                    color: 'inherit',
                                    borderRadius: theme.shape.borderRadius,
                                    padding: theme.spacing(0.5),
                                }}
                            >
                                {[10, 25, 50].map((pageSizeOption) => (
                                    <MenuItem
                                        key={pageSizeOption}
                                        value={pageSizeOption}
                                    >
                                        {pageSizeOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="h6" color={colors.grey[100]} ml={1}>
                            {`${firstRowIndex}-${lastRowIndex} of ${rowCount}`}
                        </Typography>
                        <Pagination
                            color="primary"
                            count={Math.ceil(rowCount / selectedPageSize)}
                            page={page + 1}
                            onChange={(event, newPage) =>
                                onPageChange(newPage - 1)
                            }
                            sx={{
                                '.Mui-selected': {
                                    backgroundColor: colors.blueAccent[900],
                                    color: colors.grey[100],
                                },
                                '& .MuiPaginationItem-root:hover': {
                                    backgroundColor: colors.blueAccent[800],
                                },
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default CustomFooter;
