import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import Team from './scenes/team';
import Invoices from './scenes/invoices';
import Contacts from './scenes/contacts';
import Bar from './scenes/bar';
import Form from './scenes/form';
import Line from './scenes/line';
import Pie from './scenes/pie';
import FAQ from './scenes/faq';
import Property from './scenes/property';
import Geography from './scenes/geography';
import Inspection from './scenes/inspection';
import Landing from './scenes/landing';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import Calendar from './scenes/calendar';
import LoginPage from './scenes/login';
import People from './scenes/people';
import Warranty from './scenes/warranty';
import Document from './scenes/documents';
import UnauthorizedPage from './scenes/unauthorized';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import useAuth from './hooks/useAuth';

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { auth } = useAuth();
    const isLoggedIn = !!auth?.accessToken;

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setIsSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    {isLoggedIn && (
                        <Topbar
                            toggleSidebar={toggleSidebar}
                            isMobile={isMobile}
                        />
                    )}
                    {isLoggedIn && !isMobile && (
                        <Sidebar
                            isSidebarOpen={isSidebarOpen}
                            isMobile={isMobile}
                            toggleSidebar={toggleSidebar}
                        />
                    )}
                    {isLoggedIn && isMobile && isSidebarOpen && (
                        <Sidebar
                            isSidebarOpen={isSidebarOpen}
                            isMobile={isMobile}
                            toggleSidebar={toggleSidebar}
                        />
                    )}
                    <main className="content">
                        <Routes>
                            <Route element={<PersistLogin />}>
                                <Route
                                    element={
                                        <RequireAuth allowedRoles={['admin']} />
                                    }
                                >
                                    <Route
                                        path="/contacts"
                                        element={<Contacts />}
                                    />
                                    <Route
                                        path="/invoices"
                                        element={<Invoices />}
                                    />
                                    <Route path="/form" element={<Form />} />
                                    <Route path="/bar" element={<Bar />} />
                                    <Route path="/pie" element={<Pie />} />
                                    <Route path="/line" element={<Line />} />
                                    <Route path="/faq" element={<FAQ />} />
                                    <Route
                                        path="/calendar"
                                        element={<Calendar />}
                                    />
                                    <Route
                                        path="/geography"
                                        element={<Geography />}
                                    />
                                    <Route
                                        path="/dashboard"
                                        element={<Dashboard />}
                                    />
                                </Route>
                                <Route path="/team" element={<Team />} />
                                <Route
                                    path="/inspection"
                                    element={<Inspection />}
                                />
                                <Route
                                    path="/property"
                                    element={<Property />}
                                />
                                <Route path="/people" element={<People />} />
                                <Route
                                    path="/warranty"
                                    element={<Warranty />}
                                />
                                <Route
                                    path="/document"
                                    element={<Document />}
                                />
                            </Route>
                            <Route path="/" element={<Landing />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                path="/unauthorized"
                                element={<UnauthorizedPage />}
                            />
                        </Routes>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
