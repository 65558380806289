import {
    Snackbar,
    Alert,
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
} from '@mui/material';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Header from '../../components/Header';
import { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import MuiPagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import { calculateShowAddToTeamButton } from '../people/RoleManager';
import useInspectionData from '../../hooks/useInspectionData';
import AddInspectionModal from './AddInspectionModal';
import DeleteInspectionModal from './DeleteInspectionModal';
import ViewInspectionModal from './ViewInspectionModal';
import EditInspectionModal from './EditInspectionModal';
import ScheduleFixModal from './ScheduleFixModal';
import ScheduleFollowUpModal from './ScheduleFollowUpModal';
import CalledInModal from './CalledInModal';
import CircularLoading from '../global/CircularLoading';

const Inspection = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);
    const { auth } = useAuth();
    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);
    const { loading, dropdownData, fetchInspections } = useInspectionData();
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isFixModalOpen, setFixModalOpen] = useState(false);
    const [isFollowModalOpen, setFollowModalOpen] = useState(false);
    const [isCallinModalOpen, setCallinModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [checkedInspections, setCheckedInspections] = useState([]);
    const [selectedInspection, setSelectedInspection] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1300);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleResetButtonClick = () => {
        setCheckedInspections([]);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (snackbarOpen) {
            fetchInspections();
        }

        const closeSnackbarOnOutsideClick = (event) => {
            if (snackbarOpen && !event.target.closest('.MuiAlert-root')) {
                setSnackbarOpen(false);
            }
        };

        document.addEventListener('click', closeSnackbarOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSnackbarOnOutsideClick);
        };
    }, [snackbarOpen, fetchInspections]);

    const handlePassCellClick = (id) => {
        const selectedCallinInspection = dropdownData.find(
            (inspection) => inspection.id === id
        );
        setSelectedInspection(selectedCallinInspection);
        setViewModalOpen(true);
    };

    const handleCallinCellClick = (id) => {
        const selectedCallinInspection = dropdownData.find(
            (inspection) => inspection.id === id
        );
        setSelectedInspection(selectedCallinInspection);
        setCallinModalOpen(true);
    };

    const handleFixCellClick = (id) => {
        const selectedCallinInspection = dropdownData.find(
            (inspection) => inspection.id === id
        );
        setSelectedInspection(selectedCallinInspection);
        setFixModalOpen(true);
    };

    const handleFollowCellClick = (id) => {
        const selectedCallinInspection = dropdownData.find(
            (inspection) => inspection.id === id
        );
        setSelectedInspection(selectedCallinInspection);
        setFollowModalOpen(true);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'project',
            headerName: 'Project',
            width: isMobile ? 150 : 200,
            valueGetter: (params) => params.row.address.project.title,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: isMobile ? 150 : 200,
            cellClassName: 'name-column--cell',
            valueGetter: (params) => params.row.address.title,
        },
        {
            field: 'inspection_type',
            headerName: 'Inspection Type',
            width: isMobile ? 150 : 200,
        },
        {
            field: 'call_in_date',
            headerName: 'Call in Date',
            width: isMobile ? 150 : 200,
            renderCell: (params) => (
                <div
                    style={{
                        cursor: 'pointer',
                        color: colors.greenAccent[500],
                        transition: 'color 0.3s',
                    }}
                    onClick={() =>
                        showAddToTeamButton &&
                        handleCallinCellClick(params.row.id)
                    }
                    onMouseEnter={(e) => {
                        e.target.style.color = colors.greenAccent[700];
                        e.target.style.textDecoration = 'underline';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = colors.greenAccent[500];
                        e.target.style.textDecoration = 'none';
                    }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: 'scheduled_fix',
            headerName: 'Scheduled Fix Date',
            width: isMobile ? 150 : 200,
            renderCell: (params) => (
                <div
                    style={{
                        cursor: 'pointer',
                        color: colors.greenAccent[500],
                        transition: 'color 0.3s',
                    }}
                    onClick={() =>
                        showAddToTeamButton && handleFixCellClick(params.row.id)
                    }
                    onMouseEnter={(e) => {
                        e.target.style.color = colors.greenAccent[700];
                        e.target.style.textDecoration = 'underline';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = colors.greenAccent[500];
                        e.target.style.textDecoration = 'none';
                    }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: 'scheduled_followup',
            headerName: 'Scheduled Follow-up Date',
            width: isMobile ? 150 : 200,
            renderCell: (params) => (
                <div
                    style={{
                        cursor: 'pointer',
                        color: colors.greenAccent[500],
                        transition: 'color 0.3s',
                    }}
                    onClick={() =>
                        showAddToTeamButton &&
                        handleFollowCellClick(params.row.id)
                    }
                    onMouseEnter={(e) => {
                        e.target.style.color = colors.greenAccent[700];
                        e.target.style.textDecoration = 'underline';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = colors.greenAccent[500];
                        e.target.style.textDecoration = 'none';
                    }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: 'pass',
            headerName: 'Pass/Fail',
            width: isMobile ? 100 : 200,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    {params.value !== null ? (
                        <Button
                            variant="contained"
                            sx={{
                                color: 'white',
                                backgroundColor: params.value
                                    ? colors.greenAccent[500]
                                    : colors.redAccent[500],
                                '&:hover': {
                                    backgroundColor: params.value
                                        ? colors.greenAccent[700]
                                        : colors.redAccent[700],
                                },
                            }}
                            startIcon={
                                params.value ? (
                                    <DoneOutlineIcon />
                                ) : (
                                    <HighlightOffIcon />
                                )
                            }
                            onClick={() => handlePassCellClick(params.row.id)}
                        >
                            {params.value ? 'Pass' : 'Fail'}
                        </Button>
                    ) : (
                        <Typography variant="h6">-</Typography>
                    )}
                </Box>
            ),
        },
    ];

    const handleEditClick = (id) => {
        const selectedInspection = dropdownData.find(
            (inspection) => inspection.id === id
        );
        setEditModalOpen(true);
        setSelectedInspection(selectedInspection);
    };

    const inspectionDataMapped = dropdownData.map((inspection) => {
        return {
            id: inspection.id,
            project: inspection.project,
            address: inspection.address,
            inspection_type: inspection.inspection_type,
            call_in_date: inspection.call_in_date,
            scheduled_fix: inspection.scheduled_fix_date,
            scheduled_followup: inspection.scheduled_follow_up_date,
            pass: inspection.pass_fail,
        };
    });

    const CustomFooter = () => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
        const currentPage = useGridSelector(apiRef, gridPageSelector);
        const pageSize = apiRef.current.state.pagination.pageSize;
        const [selectedPageSize, setSelectedPageSize] = useState(
            pageSize || 10
        );
        const [selectedAction, setSelectedAction] = useState('');

        const handleChangePageSize = (e) => {
            const newSize = parseInt(e.target.value, 10);
            setSelectedPageSize(newSize);
            apiRef.current.setPageSize(newSize);
        };

        const handleSelectAction = (event) => {
            const action = event.target.value;
            setSelectedAction(action);

            switch (action) {
                case 'Add':
                    setAddModalOpen(true);
                    break;
                case 'Delete':
                    setDeleteModalOpen(true);
                    break;
                case 'Sched. Fix':
                    setFixModalOpen(true);
                    break;
                case 'Sched. Follow-up':
                    setFollowModalOpen(true);
                    break;
                case 'Sched. Called In':
                    setCallinModalOpen(true);
                    break;
                case 'Reset':
                    handleResetButtonClick();
                    break;
                default:
                    break;
            }

            setSelectedAction('');
        };

        const firstRowIndex = currentPage * selectedPageSize + 1;
        const lastRowIndex =
            (currentPage + 1) * selectedPageSize > apiRef.current.getRowsCount()
                ? apiRef.current.getRowsCount()
                : (currentPage + 1) * selectedPageSize;

        return (
            <Box
                display="flex"
                justifyContent={isMobile ? 'center' : 'space-between'}
                alignItems="center"
                p={isMobile ? 1 : 2}
                backgroundColor={colors.blueAccent[700]}
                height={isMobile ? 'auto' : '56px'}
                borderRadius="0 0 3px 3px"
                flexDirection={isMobile ? 'column' : 'row'}
            >
                {isMobile ? (
                    <>
                        {showAddToTeamButton && (
                            <FormControl
                                fullWidth
                                sx={{ mb: 2 }}
                                size="small"
                            >
                                <Select
                                    value={selectedAction}
                                    onChange={handleSelectAction}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select action' }}
                                    style={{
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select an action
                                    </MenuItem>
                                    <MenuItem value="Add">Add</MenuItem>
                                    <MenuItem
                                        value="Delete"
                                        disabled={checkedInspections.length === 0}
                                    >
                                        Delete
                                    </MenuItem>
                                    <MenuItem value="Sched. Fix">Sched. Fix</MenuItem>
                                    <MenuItem value="Sched. Follow-up">Sched. Follow-up</MenuItem>
                                    <MenuItem value="Sched. Called In">Sched. Called In</MenuItem>
                                    <MenuItem value="Reset">Reset</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                Rows per page:
                            </Typography>
                            <FormControl
                                sx={{
                                    m: 1,
                                    minWidth: '80px',
                                }}
                                size="small"
                            >
                                <Select
                                    value={selectedPageSize}
                                    onChange={handleChangePageSize}
                                    style={{
                                        backgroundColor:
                                            selectedPageSize === ''
                                                ? colors.grey[500]
                                                : 'inherit',
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    {[10, 25, 50].map((pageSizeOption) => (
                                        <MenuItem
                                            key={pageSizeOption}
                                            value={pageSizeOption}
                                        >
                                            {pageSizeOption}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant="h6" color={colors.grey[100]} ml={1}>
                                {`${firstRowIndex}-${lastRowIndex} of ${apiRef.current.getRowsCount()}`}
                            </Typography>
                            <MuiPagination
                                color="primary"
                                count={pageCount}
                                page={currentPage + 1}
                                onChange={(event, newPage) => {
                                    apiRef.current.setPage(newPage - 1);
                                }}
                                sx={{
                                    '.Mui-selected': {
                                        backgroundColor: colors.blueAccent[900],
                                        color: colors.grey[100],
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        backgroundColor: colors.blueAccent[800],
                                    },
                                }}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        {showAddToTeamButton && (
                            <Box display="flex" alignItems="center">
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => setAddModalOpen(true)}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        mr: isMobile ? 0 : 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6">Add</Typography>
                                </Button>
                                <Button
                                    onClick={() => setDeleteModalOpen(true)}
                                    sx={{
                                        display: 'block',
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        '&:disabled': {
                                            backgroundColor: colors.redAccent[800],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        mr: isMobile ? 0 : 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                    color="primary"
                                    disabled={checkedInspections.length === 0}
                                >
                                    <Typography variant="h6">Delete</Typography>
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => setFixModalOpen(true)}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        mr: isMobile ? 0 : 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6">Sched. Fix</Typography>
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => setFollowModalOpen(true)}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        mr: isMobile ? 0 : 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6">
                                        Sched. Follow-up
                                    </Typography>
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => setCallinModalOpen(true)}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        mr: isMobile ? 0 : 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6">
                                        Sched. Called In
                                    </Typography>
                                </Button>
                                <Button
                                    onClick={handleResetButtonClick}
                                    sx={{
                                        display: 'block',
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                    }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <Typography variant="h6">Reset</Typography>
                                </Button>
                            </Box>
                        )}
                        <Box
                            display="flex"
                            justifyContent="right"
                            alignItems="center"
                            mt={isMobile ? 2 : 0}
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                Rows per page:
                            </Typography>
                            <FormControl
                                sx={{
                                    m: 1,
                                    minWidth: '80px',
                                }}
                                size="small"
                            >
                                <Select
                                    value={selectedPageSize}
                                    onChange={handleChangePageSize}
                                    style={{
                                        backgroundColor:
                                            selectedPageSize === ''
                                                ? colors.grey[500]
                                                : 'inherit',
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    {[10, 25, 50].map((pageSizeOption) => (
                                        <MenuItem
                                            key={pageSizeOption}
                                            value={pageSizeOption}
                                        >
                                            {pageSizeOption}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant="h6" color={colors.grey[100]} ml={1}>
                                {`${firstRowIndex}-${lastRowIndex} of ${apiRef.current.getRowsCount()}`}
                            </Typography>
                            <MuiPagination
                                color="primary"
                                count={pageCount}
                                page={currentPage + 1}
                                onChange={(event, newPage) => {
                                    apiRef.current.setPage(newPage - 1);
                                }}
                                sx={{
                                    '.Mui-selected': {
                                        backgroundColor: colors.blueAccent[900],
                                        color: colors.grey[100],
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        backgroundColor: colors.blueAccent[800],
                                    },
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
        );
    };

    return (
        <Box m="20px">
            <Header title="INSPECTION" subtitle="Managing the Inspections" />
            {loading ? (
                <CircularLoading isOpen={loading} />
            ) : (
                <>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarType}
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    snackbarType === 'success'
                                        ? colors.greenAccent[600]
                                        : colors.redAccent[600],
                                color: 'white',
                            }}
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                {snackbarMessage}
                            </Typography>
                        </Alert>
                    </Snackbar>
                    <Box
                        mt="40px"
                        height="75vh"
                        sx={{
                            '& .MuiDataGrid-root': {
                                border: 'none',
                                fontSize: isMobile ? '0.8rem' : '1rem',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                            '& .name-column--cell': {
                                color: colors.greenAccent[300],
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                backgroundColor: colors.primary[400],
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderTop: 'none',
                                backgroundColor: colors.blueAccent[700],
                            },
                            '& .MuiCheckbox-root': {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        <DataGrid
                            checkboxSelection={showAddToTeamButton}
                            pagination
                            disableRowSelectionOnClick
                            pageSizeOptions={[10, 25, 50]}
                            slots={{ footer: CustomFooter }}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10 },
                                },
                            }}
                            rows={inspectionDataMapped}
                            columns={columns}
                            onRowSelectionModelChange={(newSelection) => {
                                setCheckedInspections(newSelection);
                            }}
                            onCellClick={(params, event) => {
                                const isCallinColumn =
                                    params.field === 'call_in_date';
                                const isFixColumn =
                                    params.field === 'scheduled_fix';
                                const isFollowColumn =
                                    params.field === 'scheduled_followup';
                                const isPassColumn = params.field === 'pass';
                                const isEditOption =
                                    params.field === 'id' ||
                                    params.field === 'project' ||
                                    params.field === 'address' ||
                                    params.field === 'inspection_type';

                                if (isPassColumn) {
                                    handlePassCellClick(params.row.id);
                                }
                                if (showAddToTeamButton) {
                                    if (isCallinColumn) {
                                        handleCallinCellClick(params.row.id);
                                    }
                                    if (isFixColumn) {
                                        handleFixCellClick(params.row.id);
                                    }
                                    if (isFollowColumn) {
                                        handleFollowCellClick(params.row.id);
                                    }
                                    if (isEditOption) {
                                        handleEditClick(params.row.id);
                                    }
                                }
                            }}
                            rowSelectionModel={checkedInspections}
                        />
                        <CalledInModal
                            isCallinModalOpen={isCallinModalOpen}
                            setCallinModalOpen={setCallinModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedInspections={checkedInspections}
                            setCheckedInspections={setCheckedInspections}
                            selectedInspection={selectedInspection}
                            setSelectedInspection={setSelectedInspection}
                        />
                        <ScheduleFixModal
                            isFixModalOpen={isFixModalOpen}
                            setFixModalOpen={setFixModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedInspections={checkedInspections}
                            setCheckedInspections={setCheckedInspections}
                            selectedInspection={selectedInspection}
                            setSelectedInspection={setSelectedInspection}
                        />
                        <ScheduleFollowUpModal
                            isFollowModalOpen={isFollowModalOpen}
                            setFollowModalOpen={setFollowModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedInspections={checkedInspections}
                            setCheckedInspections={setCheckedInspections}
                            selectedInspection={selectedInspection}
                            setSelectedInspection={setSelectedInspection}
                        />
                        <ViewInspectionModal
                            isViewModalOpen={isViewModalOpen}
                            setViewModalOpen={setViewModalOpen}
                            selectedInspection={selectedInspection}
                            setSelectedInspection={setSelectedInspection}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <EditInspectionModal
                            isEditModalOpen={isEditModalOpen}
                            setEditModalOpen={setEditModalOpen}
                            selectedInspection={selectedInspection}
                            setSelectedInspection={setSelectedInspection}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <AddInspectionModal
                            isAddModalOpen={isAddModalOpen}
                            setAddModalOpen={setAddModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeleteInspectionModal
                            isDeleteModalOpen={isDeleteModalOpen}
                            setDeleteModalOpen={setDeleteModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedInspections={checkedInspections}
                            setCheckedInspections={setCheckedInspections}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Inspection;
