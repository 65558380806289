import React, { useState, useEffect } from 'react';
import {
    Snackbar,
    Alert,
    Box,
    Typography,
    useTheme,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tab,
    Tabs,
} from '@mui/material';
import { tokens } from '../../theme';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Header from '../../components/Header';
import useAuth from '../../hooks/useAuth';
import useWarrantyData from '../../hooks/useWarrantyData';
import CircularLoading from '../global/CircularLoading';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import CustomFooter from './CustomFooter'; // Import CustomFooter component
import { styled } from '@mui/material/styles';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { calculateShowAddToTeamButton } from '../people/RoleManager';
import ScheduleFixModal from './ScheduleFixModal';
import ReportedDateModal from './ReportedDateModal';
import CompletedDateModal from './CompletedDateModal';
import CompletedModal from './CompletedModal';
import ViewIssueModal from './ViewIssueModal';
import EditWarrantyModal from './EditWarrantyModal';
import DeleteWarrantyModal from './DeleteWarrantyModal';
import ViewPictureModal from './picture/ViewPictureModal';
import ViewDescriptionModal from './picture/ViewDescriptionModal';
import DeletePictureModal from './picture/DeletePictureModal';
import ViewPurposeModal from './invoice/ViewPurposeModal';
import DeleteInvoiceModal from './invoice/DeleteInvoiceModal';
import PaidDateModal from './invoice/PaidDateModal';
import InvoiceDateModal from './invoice/InvoiceDateModal';
import PaidModal from './invoice/PaidModal';
import EditInvoiceModal from './invoice/EditInvoiceModal';
import ViewDocDescriptionModal from './document/ViewDocDescriptionModal';
import DeleteDocumentModal from './document/DeleteDocumentModal';
import ViewDocumentModal from './document/ViewDocumentModal';
import EditPictureModal from './picture/EditPictureModal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditDocumentModal from './document/EditDocumentModal';
import AddAttachPictureModal from './AddAttachPictureModal';
import AddAttachDocumentModal from './AddAttachDocumentModal';
import AddWarrantyModal from './AddWarrantyModal';
import AddInvoiceModal from './invoice/AddInvoiceModal';
import TableHeaderCell from '../../components/TableHeaderCell';

const Warranty = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { auth } = useAuth();
    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);
    const { loading, dropdownData, fetchWarranties } = useWarrantyData();
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [page, setPage] = useState(0);
    const [pagePicture, setPagePicture] = useState(0);
    const [pageInvoice, setPageInvoice] = useState(0);
    const [pageDocument, setPageDocument] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsPerPagePicture, setRowsPerPagePicture] = useState(10);
    const [rowsPerPageInvoice, setRowsPerPageInvoice] = useState(10);
    const [rowsPerPageDocument, setRowsPerPageDocument] = useState(10);
    const [expandedWarrantyRows, setExpandedWarrantyRows] = useState({});
    const [expandedInvoiceRows, setExpandedInvoiceRows] = useState({});
    const [warrantyInvoiceMap, setWarrantyInvoiceMap] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const [isFixModalOpen, setFixModalOpen] = useState(false);
    const [isReportedDateModalOpen, setReportedDateModalOpen] = useState(false);
    const [isCompleteDateModalOpen, setCompleteDateModalOpen] = useState(false);
    const [isCompletedModalOpen, setCompletedModalOpen] = useState(false);
    const [isViewIssueModalOpen, setViewIssueModalOpen] = useState(false);
    const [isViewPictureModalOpen, setViewPictureModalOpen] = useState(false);
    const [isViewDescriptionModalOpen, setViewDescriptionModalOpen] =
        useState(false);
    const [isDeletePictureModalOpen, setDeletePictureModalOpen] =
        useState(false);
    const [selectedWarranty, setSelectedWarranty] = useState(null);
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedPicturesPerWarranty, setSelectedPicturesPerWarranty] =
        useState({});
    const [selectedInvoicesPerWarranty, setSelectedInvoicesPerWarranty] =
        useState({});
    const [selectedDocumentsPerWarranty, setSelectedDocumentsPerWarranty] =
        useState({});
    const [isViewPurposeModalOpen, setViewPurposeModalOpen] = useState(false);
    const [isDeleteInvoiceModalOpen, setDeleteInvoiceModalOpen] =
        useState(false);
    const [isViewDocDescriptionModalOpen, setViewDocDescriptionModalOpen] =
        useState(false);
    const [isDeleteDocumentModalOpen, setDeleteDocumentModalOpen] =
        useState(false);
    const [isDeleteWarrantyModalOpen, setDeleteWarrantyModalOpen] =
        useState(false);
    const [isInvoiceDateModalOpen, setInvoiceDateModalOpen] = useState(false);
    const [isPaidDateModalOpen, setPaidDateModalOpen] = useState(false);
    const [isViewDocumentModalOpen, setViewDocumentModalOpen] = useState(false);
    const [isPaidModalOpen, setPaidModalOpen] = useState(false);
    const [isEditPictureModalOpen, setEditPictureModalOpen] = useState(false);
    const [isEditDocumentModalOpen, setEditDocumentModalOpen] = useState(false);
    const [isAddAttachPictureModalOpen, setAddAttachPictureModalOpen] =
        useState(false);
    const [isAddAttachDocumentModalOpen, setAddAttachDocumentModalOpen] =
        useState(false);
    const [isEditInvoiceModalOpen, setEditInvoiceModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isAddInvoiceModalOpen, setAddInvoiceModalOpen] = useState(false);

    ////////////////////////////FILTER DATA///////////////////
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [filterOptions, setFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const [picOrder, setPicOrder] = useState('asc');
    const [picOrderBy, setPicOrderBy] = useState('id');
    const [picFilterOptions, setPicFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const [invOrder, setInvOrder] = useState('asc');
    const [invOrderBy, setInvOrderBy] = useState('id');
    const [invFilterOptions, setInvFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const [docOrder, setDocOrder] = useState('asc');
    const [docOrderBy, setDocOrderBy] = useState('id');
    const [docFilterOptions, setDocFilterOptions] = useState({
        column: '',     // Column to filter on
        value: '',      // Filter value
        operator: 'contains' // Default filter operator (e.g., 'contains', 'equals', etc.)
    });

    const handleSelectAction = (action) => {
        switch (action) {
            case 'Add':
                setAddModalOpen(true);
                break;
            case 'Delete':
                setDeleteWarrantyModalOpen(true);
                break;
            case 'Set Reported Date':
                setReportedDateModalOpen(true);
                break;
            case 'Sched. Fix':
                setFixModalOpen(true);
                break;
            case 'Set Completion Date':
                setCompleteDateModalOpen(true);
                break;
            case 'Set Completed':
                setCompletedModalOpen(true);
                break;
            case 'Reset':
                resetValues();
                break;
            default:
                break;
        }
    };

    const handleSelectActionPicture = (action, row) => {
        setSelectedWarranty(row); // Set the selected warranty
        switch (action) {
            case 'Add':
                setAddAttachPictureModalOpen(true);
                break;
            case 'Delete':
                setDeletePictureModalOpen(true);
                break;
            default:
                break;
        }
    };

    const handleSelectActionDocument = (action, row, invoice) => {
        setSelectedWarranty(row); // Set the selected warranty
        setSelectedInvoice(invoice); // Set the selected invoice
        switch (action) {
            case 'Add':
                setAddAttachDocumentModalOpen(true);
                break;
            case 'Delete':
                setDeleteDocumentModalOpen(true);
                break;
            default:
                break;
        }
    };

    const handleSelectActionInvoice = (action, row) => {
        setSelectedWarranty(row); // Set the selected warranty
        switch (action) {
            case 'Add':
                setAddInvoiceModalOpen(true);
                break;
            case 'Delete':
                setDeleteInvoiceModalOpen(true);
                break;
            case 'Set Invoice Date':
                setInvoiceDateModalOpen(true);
                break;
            case 'Set Paid Date':
                setPaidDateModalOpen(true);
                break;
            case 'Set Paid':
                setPaidModalOpen(true);
                break;
            default:
                break;
        }
    };

    const handleFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setFilterOptions(filterOptions);
    };

    // Inside your table component
    const handleRequestSort = (direction, column) => {
        setOrder(direction);
        setOrderBy(column);
    };

    const handlePicFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setPicFilterOptions(filterOptions);
    };

    // Inside your table component
    const handlePicRequestSort = (direction, column) => {
        setPicOrder(direction);
        setPicOrderBy(column);
    };

    const handleInvFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setInvFilterOptions(filterOptions);
    };

    // Inside your table component
    const handleInvRequestSort = (direction, column) => {
        setInvOrder(direction);
        setInvOrderBy(column);
    };

    const handleDocFilter = (filterOptions) => {
        // Implement filter logic based on filterOptions
        setDocFilterOptions(filterOptions);
    };

    // Inside your table component
    const handleDocRequestSort = (direction, column) => {
        setDocOrder(direction);
        setDocOrderBy(column);
    };

    const filteredData = () => {
        let data = dropdownData;

        // Tab-based filtering
        switch (tabValue) {
            case 0: // All
                break;
            case 1: // Completed
                data = data.filter((row) => row.completed);
                break;
            case 2: // Incomplete
                data = data.filter((row) => !row.completed);
                break;
            default:
                break;
        }

        // Apply filter based on filterOptions
        if (filterOptions.column) {
            data = data.filter(row => {
                const value = getNestedValue(row, filterOptions.column);
                const filterValue = filterOptions.value ? filterOptions.value.toLowerCase() : '';

                switch (filterOptions.operator) {
                    case 'contains':
                        return value != null && value.toString().toLowerCase().includes(filterValue);
                    case 'equals':
                        return value != null && value.toString().toLowerCase() === filterValue;
                    case 'startsWith':
                        return value != null && value.toString().toLowerCase().startsWith(filterValue);
                    case 'endsWith':
                        return value != null && value.toString().toLowerCase().endsWith(filterValue);
                    case 'isEmpty':
                        return value === '' || value == null;
                    case 'isNotEmpty':
                        return value !== '' && value != null;
                    case 'isAnyOf':
                        return value != null && filterValue.split(',').map(v => v.trim()).includes(value.toString());
                    default:
                        return true;
                }
            });
        }

        // Sorting
        return data.sort(getComparator(order, orderBy));
    };

    const filteredRow = (rowData, filterOptions, orderData, orderDataBy) => {
        let data = rowData;

        // Apply filter based on filterOptions
        if (filterOptions.column) {
            data = data.filter(row => {
                const value = getNestedValue(row, filterOptions.column);
                const filterValue = filterOptions.value ? filterOptions.value.toLowerCase() : '';

                switch (filterOptions.operator) {
                    case 'contains':
                        return value != null && value.toString().toLowerCase().includes(filterValue);
                    case 'equals':
                        return value != null && value.toString().toLowerCase() === filterValue;
                    case 'startsWith':
                        return value != null && value.toString().toLowerCase().startsWith(filterValue);
                    case 'endsWith':
                        return value != null && value.toString().toLowerCase().endsWith(filterValue);
                    case 'isEmpty':
                        return value === '' || value == null;
                    case 'isNotEmpty':
                        return value !== '' && value != null;
                    case 'isAnyOf':
                        return value != null && filterValue.split(',').map(v => v.trim()).includes(value.toString());
                    default:
                        return true;
                }
            });
        }

        // Sorting
        return data.sort(getComparator(orderData, orderDataBy));
    };


    // Utility function to access nested properties
    function getNestedValue(obj, path) {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    function descendingComparator(a, b, orderBy) {
        const aValue = getNestedValue(a, orderBy);
        const bValue = getNestedValue(b, orderBy);

        // Handle null or undefined values
        if (aValue === null || aValue === undefined) {
            return (bValue === null || bValue === undefined) ? 0 : -1;
        }
        if (bValue === null || bValue === undefined) {
            return 1;
        }

        if (bValue < aValue) {
            return -1;
        }
        if (bValue > aValue) {
            return 1;
        }
        return 0;
    }


    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    //////////////////////////////////////////////////////////

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (snackbarOpen) {
            fetchWarranties();
        }

        const closeSnackbarOnOutsideClick = (event) => {
            if (snackbarOpen && !event.target.closest('.MuiAlert-root')) {
                setSnackbarOpen(false);
            }
        };

        document.addEventListener('click', closeSnackbarOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSnackbarOnOutsideClick);
        };
    }, [snackbarOpen, fetchWarranties]);

    const handleCheckboxChange = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [...selectedRows];

        if (selectedIndex === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(selectedIndex, 1);
        }

        setSelectedRows(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = dropdownData.map((row) => row.id);
            setSelectedRows(newSelected);
        } else {
            setSelectedRows([]);
        }
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    ////////////////////////////////////////////////////////////////////////////////////
    // Modify checkbox handling functions
    const handleCheckboxChangePicture = (event, pictureId, warrantyId) => {
        const selectedPicturesForWarranty =
            selectedPicturesPerWarranty[warrantyId] || [];
        const index = selectedPicturesForWarranty.indexOf(pictureId);

        if (index === -1) {
            // Add the picture ID to the selected pictures for this warranty row
            setSelectedPicturesPerWarranty({
                ...selectedPicturesPerWarranty,
                [warrantyId]: [...selectedPicturesForWarranty, pictureId],
            });
        } else {
            // Remove the picture ID from the selected pictures for this warranty row
            setSelectedPicturesPerWarranty({
                ...selectedPicturesPerWarranty,
                [warrantyId]: selectedPicturesForWarranty.filter(
                    (id) => id !== pictureId
                ),
            });
        }
    };

    const handleCheckboxChangeInvoice = (event, invoiceId, warrantyId) => {
        const selectedInvoicesForWarranty =
            selectedInvoicesPerWarranty[warrantyId] || [];
        const index = selectedInvoicesForWarranty.indexOf(invoiceId);

        if (index === -1) {
            // Add the picture ID to the selected pictures for this warranty row
            setSelectedInvoicesPerWarranty({
                ...selectedInvoicesPerWarranty,
                [warrantyId]: [...selectedInvoicesForWarranty, invoiceId],
            });
        } else {
            // Remove the picture ID from the selected pictures for this warranty row
            setSelectedInvoicesPerWarranty({
                ...selectedInvoicesPerWarranty,
                [warrantyId]: selectedInvoicesForWarranty.filter(
                    (id) => id !== invoiceId
                ),
            });
        }
    };

    const handleCheckboxChangeDocument = (
        event,
        documentId,
        invoiceId,
        warrantyId
    ) => {
        // Get the selected documents for the specified warranty and invoice or initialize it as an empty array
        const selectedDocumentsForWarranty =
            selectedDocumentsPerWarranty[warrantyId] || {};
        const selectedDocumentsForInvoice =
            selectedDocumentsForWarranty[invoiceId] || [];

        // Check if the documentId exists in the selected documents for the invoice
        const index = selectedDocumentsForInvoice.indexOf(documentId);

        // If the documentId doesn't exist, add it; otherwise, remove it
        const updatedDocumentsForInvoice =
            index === -1
                ? [...selectedDocumentsForInvoice, documentId]
                : selectedDocumentsForInvoice.filter((id) => id !== documentId);

        // Update the selected documents for the specified warranty and invoice
        setSelectedDocumentsPerWarranty({
            ...selectedDocumentsPerWarranty,
            [warrantyId]: {
                ...selectedDocumentsForWarranty,
                [invoiceId]: updatedDocumentsForInvoice,
            },
        });
    };

    // const handleCheckboxChangeDocument = (event, id) => {
    //   const selectedIndex = selectedRowsDocument.indexOf(id);
    //   let newSelected = [...selectedRowsDocument];

    //   if (selectedIndex === -1) {
    //     newSelected.push(id);
    //   } else {
    //     newSelected.splice(selectedIndex, 1);
    //   }

    //   setSelectedRowsDocument(newSelected);
    // };

    const handleSelectAllClickPicture = (event, row) => {
        if (event.target.checked) {
            const pictureIds = row.pictures.map((picture) => picture.id);
            setSelectedPicturesPerWarranty({
                ...selectedPicturesPerWarranty,
                [row.id]: pictureIds,
            });
        } else {
            setSelectedPicturesPerWarranty({
                ...selectedPicturesPerWarranty,
                [row.id]: [],
            });
        }
    };

    const handleSelectAllClickInvoice = (event, row) => {
        if (event.target.checked) {
            const invoiceIds = row.invoices.map((invoice) => invoice.id);
            setSelectedInvoicesPerWarranty({
                ...selectedInvoicesPerWarranty,
                [row.id]: invoiceIds,
            });
        } else {
            setSelectedInvoicesPerWarranty({
                ...selectedInvoicesPerWarranty,
                [row.id]: [],
            });
        }
    };

    const handleSelectAllClickDocument = (event, row, invoice) => {
        if (event.target.checked) {
            const documentIds = invoice.documents.map(
                (document) => document.id
            );
            setSelectedDocumentsPerWarranty({
                ...selectedDocumentsPerWarranty,
                [row.id]: {
                    ...selectedDocumentsPerWarranty[row.id],
                    [invoice.id]: documentIds,
                },
            });
        } else {
            setSelectedDocumentsPerWarranty({
                ...selectedDocumentsPerWarranty,
                [row.id]: {
                    ...selectedDocumentsPerWarranty[row.id],
                    [invoice.id]: [],
                },
            });
        }
    };

    // const handleSelectAllClickDocument = (event, row) => {
    //   if (event.target.checked) {
    //     // Extract invoice data from dropdownData
    //     const documentData = row.invoices.map((invoice) => invoice.documents).flat(); // Adjust the property according to your data structure
    //     const newSelected = documentData.map((document) => document.id);
    //     setSelectedRowsDocument(newSelected);
    //   } else {
    //     setSelectedRowsDocument([]);
    //   }
    // };

    const isPictureSelected = (id, warrantyId) => {
        const selectedPicturesForWarranty =
            selectedPicturesPerWarranty[warrantyId] || [];
        return selectedPicturesForWarranty.indexOf(id) !== -1;
    };

    const isInvoiceSelected = (id, warrantyId) => {
        const selectedInvoicesForWarranty =
            selectedInvoicesPerWarranty[warrantyId] || [];
        return selectedInvoicesForWarranty.indexOf(id) !== -1;
    };

    const isDocumentSelected = (id, warrantyId, invoiceId) => {
        const selectedDocumentsForWarranty =
            selectedDocumentsPerWarranty[warrantyId]?.[invoiceId] || [];
        return selectedDocumentsForWarranty.indexOf(id) !== -1;
    };

    ////////////////////////////////////////////////////////////////////////////////////
    // Function to toggle expansion of warranty rows
    const toggleWarrantyRowExpansion = (warrantyId) => {
        setExpandedWarrantyRows((prevExpandedWarrantyRows) => {
            const newState = !prevExpandedWarrantyRows[warrantyId];
            const updatedWarrantyRows = {
                ...prevExpandedWarrantyRows,
                [warrantyId]: newState,
            };

            // If collapsing, collapse associated invoice rows
            if (!newState) {
                const invoicesToCollapse = warrantyInvoiceMap[warrantyId] || [];
                invoicesToCollapse.forEach((invoiceId) => {
                    setExpandedInvoiceRows((prevExpandedInvoiceRows) => ({
                        ...prevExpandedInvoiceRows,
                        [invoiceId]: false,
                    }));
                });
                // Remove collapsed invoices from warrantyInvoiceMap
                setWarrantyInvoiceMap((prevMap) => {
                    const updatedMap = { ...prevMap };
                    delete updatedMap[warrantyId];
                    return updatedMap;
                });
            }
            return updatedWarrantyRows;
        });
    };

    // Function to toggle expansion of invoice rows
    const toggleInvoiceRowExpansion = (warrantyId, invoiceId) => {
        setExpandedInvoiceRows((prevExpandedInvoiceRows) => {
            const newState = !prevExpandedInvoiceRows[invoiceId];
            const updatedInvoiceRows = {
                ...prevExpandedInvoiceRows,
                [invoiceId]: newState,
            };

            // If expanding, add invoice to warrantyInvoiceMap
            if (newState) {
                setWarrantyInvoiceMap((prevMap) => ({
                    ...prevMap,
                    [warrantyId]: [...(prevMap[warrantyId] || []), invoiceId],
                }));
            }

            return updatedInvoiceRows;
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangePagePicture = (event, newPage) => {
        setPagePicture(newPage);
    };

    const handleChangePageInvoice = (event, newPage) => {
        setPageInvoice(newPage);
    };

    const handleChangePageDocument = (event, newPage) => {
        setPageDocument(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeRowsPerPagePicture = (event) => {
        setRowsPerPagePicture(parseInt(event.target.value, 10));
        setPagePicture(0);
    };

    const handleChangeRowsPerPageInvoice = (event) => {
        setRowsPerPageInvoice(parseInt(event.target.value, 10));
        setPageInvoice(0);
    };

    const handleChangeRowsPerPageDocument = (event) => {
        setRowsPerPageDocument(parseInt(event.target.value, 10));
        setPageDocument(0);
    };

    // Define a styled TableCell component
    const StyledTableCell = styled(TableCell)({
        color: colors.greenAccent[500], // Set the text color to greenAccent[500]
        transition: 'color 0.3s', // Add a transition effect for color change
        '&:hover': {
            color: colors.greenAccent[700], // Change the text color to greenAccent[700] on hover
            textDecoration: 'underline', // Underline the text on hover
        },
    });

    // Then use StyledTableCell in your component

    const handleViewPictureClick = () => {
        setViewPictureModalOpen(true);
    };

    const handleEditPictureClick = () => {
        setEditPictureModalOpen(true);
    };

    const handleEditDocumentClick = () => {
        setEditDocumentModalOpen(true);
    };

    const handleViewDocumentClick = () => {
        setViewDocumentModalOpen(true);
    };

    const handleViewPictureDescriptionClick = () => {
        setViewDescriptionModalOpen(true);
    };

    const handleViewDocumentDescriptionClick = () => {
        setViewDocDescriptionModalOpen(true);
    };

    const handleViewInvoicePurposeClick = () => {
        setViewPurposeModalOpen(true);
    };

    const resetValues = (event) => {
        setExpandedWarrantyRows({});
        setExpandedInvoiceRows({});
        setWarrantyInvoiceMap({});
        setPage(0);
        setPageInvoice(0);
        setPagePicture(0);
        setPageDocument(0);
        setSelectedRows([]);
        setSelectedPicturesPerWarranty({});
        setSelectedInvoicesPerWarranty({});
        setSelectedDocumentsPerWarranty({});
    };

    const handleTabChange = (event, newValue) => {
        setExpandedWarrantyRows({});
        setExpandedInvoiceRows({});
        setWarrantyInvoiceMap({});
        setPage(0);
        setPageInvoice(0);
        setPagePicture(0);
        setPageDocument(0);
        setSelectedRows([]);
        setSelectedPicturesPerWarranty({});
        setSelectedInvoicesPerWarranty({});
        setSelectedDocumentsPerWarranty({});
        setTabValue(newValue);
    };

    const handleInvoiceEditCellClick = (id, invoice_id) => {
        const selectedReportWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        const selectedReportInvoice = selectedReportWarranty.invoices.find(
            (invoice) => invoice.id === invoice_id
        );
        setSelectedInvoice(selectedReportInvoice);
        setSelectedWarranty(selectedReportWarranty);
        setEditInvoiceModalOpen(true);
    };

    const handleInvoiceDateCellClick = (id, invoice_id) => {
        const selectedReportWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        const selectedReportInvoice = selectedReportWarranty.invoices.find(
            (invoice) => invoice.id === invoice_id
        );
        setSelectedInvoice(selectedReportInvoice);
        setSelectedWarranty(selectedReportWarranty);
        setInvoiceDateModalOpen(true);
    };

    const handlePaidDateCellClick = (id, invoice_id) => {
        const selectedReportWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        const selectedReportInvoice = selectedReportWarranty.invoices.find(
            (invoice) => invoice.id === invoice_id
        );
        setSelectedInvoice(selectedReportInvoice);
        setSelectedWarranty(selectedReportWarranty);
        setPaidDateModalOpen(true);
    };

    const handlePaidCellClick = (id, invoice_id) => {
        const selectedReportWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        const selectedReportInvoice = selectedReportWarranty.invoices.find(
            (invoice) => invoice.id === invoice_id
        );
        setSelectedInvoice(selectedReportInvoice);
        setSelectedWarranty(selectedReportWarranty);
        setPaidModalOpen(true);
    };

    const handleFixCellClick = (id) => {
        const selectedFixWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        setSelectedWarranty(selectedFixWarranty);
        setFixModalOpen(true);
    };

    const handleReportedDateCellClick = (id) => {
        const selectedReportWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        setSelectedWarranty(selectedReportWarranty);
        setReportedDateModalOpen(true);
    };

    const handleEditCellClick = (id) => {
        const selectedReportWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        setSelectedWarranty(selectedReportWarranty);
        setEditModalOpen(true);
    };

    const handleCompleteDateCellClick = (id) => {
        const selectedCompleteWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        setSelectedWarranty(selectedCompleteWarranty);
        setCompleteDateModalOpen(true);
    };

    const handleCompletedCellClick = (id) => {
        const selectedCompleteWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        setSelectedWarranty(selectedCompleteWarranty);
        setCompletedModalOpen(true);
    };

    const handleViewCellClick = (id) => {
        const selectedViewWarranty = dropdownData.find(
            (warranty) => warranty.id === id
        );
        setSelectedWarranty(selectedViewWarranty);
        setViewIssueModalOpen(true);
    };

    const somePicturesSelectedInCurrentWarranty = (row) => {
        const selectedPicturesForWarranty =
            selectedPicturesPerWarranty[row.id] || [];
        return selectedPicturesForWarranty.length > 0;
    };

    const allPicturesSelectedInCurrentWarranty = (row) => {
        const selectedPicturesForWarranty =
            selectedPicturesPerWarranty[row.id] || [];
        return selectedPicturesForWarranty.length === row.pictures.length;
    };

    const someInvoicesSelectedInCurrentWarranty = (row) => {
        const selectedInvoicesForWarranty =
            selectedInvoicesPerWarranty[row.id] || [];
        return selectedInvoicesForWarranty.length > 0;
    };

    const allInvoicesSelectedInCurrentWarranty = (row) => {
        const selectedInvoicesForWarranty =
            selectedInvoicesPerWarranty[row.id] || [];
        return selectedInvoicesForWarranty.length === row.invoices.length;
    };

    const someDocumentsSelectedInCurrentWarranty = (row, invoice) => {
        const selectedDocumentsForWarranty =
            selectedDocumentsPerWarranty[row.id]?.[invoice.id] || [];
        return selectedDocumentsForWarranty.length > 0;
    };

    const allDocumentsSelectedInCurrentWarranty = (row, invoice) => {
        const selectedDocumentsForWarranty =
            selectedDocumentsPerWarranty[row.id]?.[invoice.id] || [];
        return selectedDocumentsForWarranty.length === invoice.documents.length;
    };

    const columns = [
        { columnHeaderName: 'ID', column: 'id', sortable: true, center: false },
        { columnHeaderName: 'Project Title', column: 'address.project.title', sortable: true, center: false },
        { columnHeaderName: 'Address Title', column: 'address.title', sortable: true, center: false },
        { columnHeaderName: 'Reported Date', column: 'reported_date', sortable: true, center: false },
        { columnHeaderName: 'Scheduled Fix Date', column: 'scheduled_fix_date', sortable: true, center: false },
        { columnHeaderName: 'Completion Date', column: 'completion_date', sortable: true, center: false },
        { columnHeaderName: 'Completed', column: 'completed', sortable: true, center: true },
        { columnHeaderName: 'Issue', column: 'issue', sortable: true, center: true }
    ];

    const docColumns = [
        { columnHeaderName: 'ID', column: 'id', sortable: true, center: false },
        { columnHeaderName: 'Title', column: 'title', sortable: true, center: false },
        { columnHeaderName: 'Description', column: 'description', sortable: true, center: false },
        { columnHeaderName: 'View', column: 'View', sortable: false, center: true },
        { columnHeaderName: 'Edit', column: 'Edit', sortable: false, center: true },
    ];

    const invColumns = [
        { columnHeaderName: 'ID', column: 'id', sortable: true, center: false },
        { columnHeaderName: 'Company', column: 'company', sortable: true, center: false },
        { columnHeaderName: 'In', column: 'in_number', sortable: true, center: false },
        { columnHeaderName: 'Out', column: 'out_number', sortable: true, center: false },
        { columnHeaderName: 'Invoice Date', column: 'invoice_date', sortable: true, center: false },
        { columnHeaderName: 'Date Paid', column: 'date_paid', sortable: true, center: false },
        { columnHeaderName: 'Paid', column: 'paid', sortable: true, center: true },
        { columnHeaderName: 'Purpose', column: 'purpose', sortable: true, center: false },
    ];

    return (
        <Box m="20px">
            <Header title="WARRANTY" subtitle="Managing the Warranties" />
            {loading ? (
                <CircularLoading isOpen={loading} />
            ) : (
                <>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{
                            vertical: 'top', // Set the vertical position to 'top'
                            horizontal: 'center', // Center horizontally
                        }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={
                                snackbarType
                                    ? snackbarType === 'success'
                                        ? 'success'
                                        : snackbarType === 'warning'
                                          ? 'warning'
                                          : 'error'
                                    : 'info'
                            }
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    snackbarType === 'success'
                                        ? colors.greenAccent[600]
                                        : snackbarType === 'warning'
                                          ? '#FFFF8F'
                                          : colors.redAccent[600],
                                color:
                                    snackbarType === 'warning'
                                        ? colors.primary[300]
                                        : colors.grey[100],
                            }}
                        >
                            <Typography
                                variant="h6"
                                color={
                                    snackbarType === 'warning'
                                        ? colors.primary[300]
                                        : colors.grey[100]
                                }
                            >
                                {snackbarMessage}
                            </Typography>
                        </Alert>
                    </Snackbar>
                    <Box
                        m="40px 0 0 0"
                        height="65vh"
                        sx={{
                            '& .MuiTableHead-root': {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: 'none',
                            },
                            '& .MuiTablePagination-root': {
                                borderTop: 'none',
                                backgroundColor: colors.blueAccent[700],
                            },
                            '& .MuiCheckbox-root': {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="Warranty Tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{
                                backgroundColor: colors.primary[400],
                                borderRadius: '5px',
                            }}
                        >
                            <Tab
                                label="All"
                                sx={{
                                    '&.Mui-selected': {
                                        backgroundColor: colors.primary[200], // Change the color of the selected tab
                                    },
                                }}
                            />
                            <Tab
                                label="Completed"
                                sx={{
                                    '&.Mui-selected': {
                                        backgroundColor: colors.primary[200], // Change the color of the selected tab
                                    },
                                }}
                            />
                            <Tab
                                label="Incomplete"
                                sx={{
                                    '&.Mui-selected': {
                                        backgroundColor: colors.primary[200], // Change the color of the selected tab
                                    },
                                }}
                            />
                        </Tabs>
                        <TableContainer
                            sx={{
                                height: '100%',
                                backgroundColor: colors.primary[400],
                                borderRadius: '5px',
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>{' '}
                                        {/* New cell for the arrow icon */}
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={
                                                    selectedRows.length > 0 &&
                                                    selectedRows.length <
                                                        dropdownData.length
                                                }
                                                checked={
                                                    dropdownData.length > 0 &&
                                                    selectedRows.length ===
                                                        dropdownData.length
                                                }
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    'aria-label':
                                                        'select all inspections',
                                                }}
                                            />
                                        </TableCell>
                                        {columns.map((column) => (
                                            <TableHeaderCell
                                                key={column.column}
                                                columns={columns}
                                                column={column.column}
                                                columnHeaderName={column.columnHeaderName}
                                                order={order}
                                                orderBy={orderBy}
                                                handleRequestSort={handleRequestSort}
                                                handleFilter={handleFilter}
                                                sortable={column.sortable}
                                                center={column.center}
                                            />
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData()
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => {
                                            const isItemSelected = isSelected(
                                                row.id
                                            );
                                            const isExpandedWarranty =
                                                expandedWarrantyRows[row.id]; // Check warranty expansion
                                            return (
                                                <React.Fragment key={row.id}>
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={
                                                            isItemSelected
                                                        }
                                                        tabIndex={-1}
                                                        selected={
                                                            isItemSelected
                                                        }
                                                        sx={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <TableCell
                                                            onClick={() =>
                                                                toggleWarrantyRowExpansion(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {isExpandedWarranty ? (
                                                                <ExpandLessIcon />
                                                            ) : (
                                                                <ExpandMoreIcon />
                                                            )}
                                                        </TableCell>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={
                                                                    isItemSelected
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleCheckboxChange(
                                                                        event,
                                                                        row.id
                                                                    )
                                                                }
                                                                inputProps={{
                                                                    'aria-labelledby': `checkbox-${row.id}`,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() =>
                                                                handleEditCellClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() =>
                                                                handleEditCellClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {row.address &&
                                                                row.address
                                                                    .project &&
                                                                row.address
                                                                    .project
                                                                    .title}
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() =>
                                                                handleEditCellClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {row.address &&
                                                                row.address
                                                                    .title}
                                                        </TableCell>
                                                        <StyledTableCell
                                                            onClick={() =>
                                                                handleReportedDateCellClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {row.reported_date}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            onClick={() =>
                                                                handleFixCellClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {
                                                                row.scheduled_fix_date
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            onClick={() =>
                                                                handleCompleteDateCellClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {
                                                                row.completion_date
                                                            }
                                                        </StyledTableCell>
                                                        <TableCell
                                                            align="center"
                                                            onClick={() =>
                                                                handleCompletedCellClick(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            {row.completed ? (
                                                                <DoneOutlineIcon
                                                                    sx={{
                                                                        color: colors
                                                                            .greenAccent[500],
                                                                    }}
                                                                />
                                                            ) : (
                                                                <HighlightOffIcon
                                                                    sx={{
                                                                        color: colors
                                                                            .redAccent[500],
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{
                                                                        color: 'white',
                                                                        backgroundColor:
                                                                            colors
                                                                                .greenAccent[500],
                                                                        '&:hover':
                                                                            {
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .greenAccent[700],
                                                                            },
                                                                    }}
                                                                    startIcon={
                                                                        <EditNoteOutlinedIcon />
                                                                    }
                                                                    onClick={() =>
                                                                        handleViewCellClick(
                                                                            row.id
                                                                        )
                                                                    }
                                                                >
                                                                    View
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    {isExpandedWarranty && (
                                                        <>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                        paddingTop: 0,
                                                                    }}
                                                                    colSpan={10}
                                                                >
                                                                    <Accordion>
                                                                        <AccordionSummary
                                                                            expandIcon={
                                                                                <ExpandMoreIcon />
                                                                            }
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .primary[400],
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                Pictures
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .primary[400],
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                // height="70vh"
                                                                                sx={{
                                                                                    '& .MuiTableHead-root':
                                                                                        {
                                                                                            backgroundColor:
                                                                                                colors
                                                                                                    .blueAccent[500],
                                                                                            borderBottom:
                                                                                                'none',
                                                                                        },
                                                                                    '& .MuiTablePagination-root':
                                                                                        {
                                                                                            borderTop:
                                                                                                'none',
                                                                                            backgroundColor:
                                                                                                colors
                                                                                                    .blueAccent[500],
                                                                                        },
                                                                                    '& .MuiCheckbox-root':
                                                                                        {
                                                                                            color: `${colors.greenAccent[200]} !important`,
                                                                                        },
                                                                                }}
                                                                            >
                                                                                <TableContainer
                                                                                    sx={{
                                                                                        height: '100%',
                                                                                        backgroundColor:
                                                                                            colors
                                                                                                .primary[400],
                                                                                        borderRadius:
                                                                                            '5px',
                                                                                    }}
                                                                                >
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                {/* <TableCell></TableCell> New cell for the arrow icon */}
                                                                                                <TableCell padding="checkbox">
                                                                                                    <Checkbox
                                                                                                        color="primary"
                                                                                                        indeterminate={
                                                                                                            somePicturesSelectedInCurrentWarranty(
                                                                                                                row
                                                                                                            ) &&
                                                                                                            !allPicturesSelectedInCurrentWarranty(
                                                                                                                row
                                                                                                            )
                                                                                                        }
                                                                                                        checked={allPicturesSelectedInCurrentWarranty(
                                                                                                            row
                                                                                                        )}
                                                                                                        onChange={(
                                                                                                            event
                                                                                                        ) =>
                                                                                                            handleSelectAllClickPicture(
                                                                                                                event,
                                                                                                                row
                                                                                                            )
                                                                                                        }
                                                                                                        inputProps={{
                                                                                                            'aria-label':
                                                                                                                'select all pictures',
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                {docColumns.map((column) => (
                                                                                                    <TableHeaderCell
                                                                                                        key={column.column}
                                                                                                        columns={docColumns}
                                                                                                        column={column.column}
                                                                                                        columnHeaderName={column.columnHeaderName}
                                                                                                        order={picOrder}
                                                                                                        orderBy={picOrderBy}
                                                                                                        handleRequestSort={handlePicRequestSort}
                                                                                                        handleFilter={handlePicFilter}
                                                                                                        sortable={column.sortable}
                                                                                                        center={column.center}
                                                                                                    />
                                                                                                ))}
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {filteredRow(row.pictures, picFilterOptions, picOrder, picOrderBy)
                                                                                                .slice(
                                                                                                    pagePicture *
                                                                                                        rowsPerPagePicture,
                                                                                                    pagePicture *
                                                                                                        rowsPerPagePicture +
                                                                                                        rowsPerPagePicture
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        picture
                                                                                                    ) => {
                                                                                                        const isItemSelected =
                                                                                                            isPictureSelected(
                                                                                                                picture.id,
                                                                                                                row.id
                                                                                                            );
                                                                                                        // const isExpanded = expandedRows[picture.id];
                                                                                                        return (
                                                                                                            <React.Fragment
                                                                                                                key={
                                                                                                                    picture.id
                                                                                                                }
                                                                                                            >
                                                                                                                <TableRow
                                                                                                                    hover
                                                                                                                    role="checkbox"
                                                                                                                    aria-checked={
                                                                                                                        isItemSelected
                                                                                                                    }
                                                                                                                    tabIndex={
                                                                                                                        -1
                                                                                                                    }
                                                                                                                    selected={
                                                                                                                        isItemSelected
                                                                                                                    }
                                                                                                                    sx={{
                                                                                                                        cursor: 'pointer',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {/* <TableCell onClick={() => toggleRowExpansion(picture.id)}>
                                                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                      </TableCell> */}
                                                                                                                    <TableCell padding="checkbox">
                                                                                                                        <Checkbox
                                                                                                                            color="primary"
                                                                                                                            checked={
                                                                                                                                isItemSelected
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                event
                                                                                                                            ) =>
                                                                                                                                handleCheckboxChangePicture(
                                                                                                                                    event,
                                                                                                                                    picture.id,
                                                                                                                                    row.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            inputProps={{
                                                                                                                                'aria-labelledby': `checkbox-${picture.id}`,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {
                                                                                                                            picture.id
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {
                                                                                                                            picture.title
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedPicture(
                                                                                                                                picture
                                                                                                                            );
                                                                                                                            handleViewPictureDescriptionClick();
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {picture
                                                                                                                            .description
                                                                                                                            .length >
                                                                                                                        30
                                                                                                                            ? `${picture.description.substring(0, 30)}...`
                                                                                                                            : picture.description}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell align="center">
                                                                                                                        {
                                                                                                                            <Button
                                                                                                                                variant="contained"
                                                                                                                                sx={{
                                                                                                                                    color: 'white',
                                                                                                                                    backgroundColor:
                                                                                                                                        colors
                                                                                                                                            .greenAccent[500],
                                                                                                                                    '&:hover':
                                                                                                                                        {
                                                                                                                                            backgroundColor:
                                                                                                                                                colors
                                                                                                                                                    .greenAccent[700],
                                                                                                                                        },
                                                                                                                                }}
                                                                                                                                startIcon={
                                                                                                                                    <ImageOutlinedIcon />
                                                                                                                                }
                                                                                                                                onClick={() => {
                                                                                                                                    setSelectedPicture(
                                                                                                                                        picture
                                                                                                                                    );
                                                                                                                                    setSelectedWarranty(
                                                                                                                                        row
                                                                                                                                    );
                                                                                                                                    handleViewPictureClick();
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                View
                                                                                                                            </Button>
                                                                                                                        }
                                                                                                                    </TableCell>

                                                                                                                    <TableCell align="center">
                                                                                                                        {
                                                                                                                            <Button
                                                                                                                                variant="outlined"
                                                                                                                                sx={{
                                                                                                                                    color: colors
                                                                                                                                        .grey[100],
                                                                                                                                    borderColor:
                                                                                                                                        colors
                                                                                                                                            .grey[100],
                                                                                                                                }}
                                                                                                                                startIcon={
                                                                                                                                    <EditOutlinedIcon />
                                                                                                                                }
                                                                                                                                onClick={() => {
                                                                                                                                    setSelectedWarranty(
                                                                                                                                        row
                                                                                                                                    );
                                                                                                                                    setSelectedPicture(
                                                                                                                                        picture
                                                                                                                                    );
                                                                                                                                    handleEditPictureClick();
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                Edit
                                                                                                                            </Button>
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                </TableRow>
                                                                                                            </React.Fragment>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                                <CustomFooter
                                                                                    rowCount={
                                                                                        row
                                                                                            .pictures
                                                                                            .length
                                                                                    }
                                                                                    page={
                                                                                        pagePicture
                                                                                    }
                                                                                    rowsPerPage={
                                                                                        rowsPerPagePicture
                                                                                    }
                                                                                    onPageChange={
                                                                                        handleChangePagePicture
                                                                                    }
                                                                                    onRowsPerPageChange={
                                                                                        handleChangeRowsPerPagePicture
                                                                                    }
                                                                                    backgroundColor={
                                                                                        colors
                                                                                            .blueAccent[500]
                                                                                    }
                                                                                    onActionSelect={(action) => handleSelectActionPicture(action, row)}
                                                                                    row={row} // Pass row as prop if available
                                                                                    isOuterTable={false}
                                                                                    buttons={[
                                                                                        {
                                                                                            label: 'Add',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedWarranty(
                                                                                                        row
                                                                                                    );
                                                                                                    setAddAttachPictureModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .greenAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .greenAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            label: 'Delete',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedWarranty(
                                                                                                        row
                                                                                                    );
                                                                                                    setDeletePictureModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .redAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .redAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        // Add other buttons as needed
                                                                                    ]}
                                                                                />
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                        paddingTop: 0,
                                                                    }}
                                                                    colSpan={10}
                                                                >
                                                                    <Accordion>
                                                                        <AccordionSummary
                                                                            expandIcon={
                                                                                <ExpandMoreIcon />
                                                                            }
                                                                            aria-controls="panel2a-content"
                                                                            id="panel2a-header"
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .primary[400],
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                Invoices
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    colors
                                                                                        .primary[400],
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                // height="70vh"
                                                                                sx={{
                                                                                    '& .MuiTableHead-root':
                                                                                        {
                                                                                            backgroundColor:
                                                                                                colors
                                                                                                    .blueAccent[500],
                                                                                            borderBottom:
                                                                                                'none',
                                                                                        },
                                                                                    '& .MuiTablePagination-root':
                                                                                        {
                                                                                            borderTop:
                                                                                                'none',
                                                                                            backgroundColor:
                                                                                                colors
                                                                                                    .blueAccent[500],
                                                                                        },
                                                                                    '& .MuiCheckbox-root':
                                                                                        {
                                                                                            color: `${colors.greenAccent[200]} !important`,
                                                                                        },
                                                                                }}
                                                                            >
                                                                                <TableContainer
                                                                                    sx={{
                                                                                        height: '100%',
                                                                                        backgroundColor:
                                                                                            colors
                                                                                                .primary[400],
                                                                                        borderRadius:
                                                                                            '5px',
                                                                                    }}
                                                                                >
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell></TableCell>{' '}
                                                                                                {/* New cell for the arrow icon */}
                                                                                                <TableCell padding="checkbox">
                                                                                                    <Checkbox
                                                                                                        color="primary"
                                                                                                        indeterminate={
                                                                                                            someInvoicesSelectedInCurrentWarranty(
                                                                                                                row
                                                                                                            ) &&
                                                                                                            !allInvoicesSelectedInCurrentWarranty(
                                                                                                                row
                                                                                                            )
                                                                                                        }
                                                                                                        checked={allInvoicesSelectedInCurrentWarranty(
                                                                                                            row
                                                                                                        )}
                                                                                                        onChange={(
                                                                                                            event
                                                                                                        ) =>
                                                                                                            handleSelectAllClickInvoice(
                                                                                                                event,
                                                                                                                row
                                                                                                            )
                                                                                                        }
                                                                                                        inputProps={{
                                                                                                            'aria-label':
                                                                                                                'select all invoices',
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                {invColumns.map((column) => (
                                                                                                    <TableHeaderCell
                                                                                                        key={column.column}
                                                                                                        columns={invColumns}
                                                                                                        column={column.column}
                                                                                                        columnHeaderName={column.columnHeaderName}
                                                                                                        order={invOrder}
                                                                                                        orderBy={invOrderBy}
                                                                                                        handleRequestSort={handleInvRequestSort}
                                                                                                        handleFilter={handleInvFilter}
                                                                                                        sortable={column.sortable}
                                                                                                        center={column.center}
                                                                                                    />
                                                                                                ))}
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {filteredRow(row.invoices, invFilterOptions, invOrder, invOrderBy)
                                                                                                .slice(
                                                                                                    pageInvoice *
                                                                                                        rowsPerPageInvoice,
                                                                                                    pageInvoice *
                                                                                                        rowsPerPageInvoice +
                                                                                                        rowsPerPageInvoice
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        invoice
                                                                                                    ) => {
                                                                                                        const isItemSelected =
                                                                                                            isInvoiceSelected(
                                                                                                                invoice.id,
                                                                                                                row.id
                                                                                                            );
                                                                                                        const isExpandedInvoice =
                                                                                                            expandedInvoiceRows[
                                                                                                                invoice
                                                                                                                    .id
                                                                                                            ]; // Check invoice expansion
                                                                                                        return (
                                                                                                            <React.Fragment
                                                                                                                key={
                                                                                                                    invoice.id
                                                                                                                }
                                                                                                            >
                                                                                                                <TableRow
                                                                                                                    hover
                                                                                                                    role="checkbox"
                                                                                                                    aria-checked={
                                                                                                                        isItemSelected
                                                                                                                    }
                                                                                                                    tabIndex={
                                                                                                                        -1
                                                                                                                    }
                                                                                                                    selected={
                                                                                                                        isItemSelected
                                                                                                                    }
                                                                                                                    sx={{
                                                                                                                        cursor: 'pointer',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <TableCell
                                                                                                                        onClick={() =>
                                                                                                                            toggleInvoiceRowExpansion(
                                                                                                                                row.id,
                                                                                                                                invoice.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {isExpandedInvoice ? (
                                                                                                                            <ExpandLessIcon />
                                                                                                                        ) : (
                                                                                                                            <ExpandMoreIcon />
                                                                                                                        )}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell padding="checkbox">
                                                                                                                        <Checkbox
                                                                                                                            color="primary"
                                                                                                                            checked={
                                                                                                                                isItemSelected
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                event
                                                                                                                            ) =>
                                                                                                                                handleCheckboxChangeInvoice(
                                                                                                                                    event,
                                                                                                                                    invoice.id,
                                                                                                                                    row.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            inputProps={{
                                                                                                                                'aria-labelledby': `checkbox-${invoice.id}`,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        onClick={() =>
                                                                                                                            handleInvoiceEditCellClick(
                                                                                                                                row.id,
                                                                                                                                invoice.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            invoice.id
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        onClick={() =>
                                                                                                                            handleInvoiceEditCellClick(
                                                                                                                                row.id,
                                                                                                                                invoice.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            invoice.company
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        onClick={() =>
                                                                                                                            handleInvoiceEditCellClick(
                                                                                                                                row.id,
                                                                                                                                invoice.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            invoice.in_number
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        onClick={() =>
                                                                                                                            handleInvoiceEditCellClick(
                                                                                                                                row.id,
                                                                                                                                invoice.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            invoice.out_number
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <StyledTableCell
                                                                                                                        onClick={() =>
                                                                                                                            handleInvoiceDateCellClick(
                                                                                                                                row.id,
                                                                                                                                invoice.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            invoice.invoice_date
                                                                                                                        }
                                                                                                                    </StyledTableCell>
                                                                                                                    <StyledTableCell
                                                                                                                        onClick={() =>
                                                                                                                            handlePaidDateCellClick(
                                                                                                                                row.id,
                                                                                                                                invoice.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            invoice.date_paid
                                                                                                                        }
                                                                                                                    </StyledTableCell>
                                                                                                                    <TableCell
                                                                                                                        align="center"
                                                                                                                        onClick={() =>
                                                                                                                            handlePaidCellClick(
                                                                                                                                row.id,
                                                                                                                                invoice.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {invoice.paid ? (
                                                                                                                            <DoneOutlineIcon
                                                                                                                                sx={{
                                                                                                                                    color: colors
                                                                                                                                        .greenAccent[500],
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        ) : (
                                                                                                                            <HighlightOffIcon
                                                                                                                                sx={{
                                                                                                                                    color: colors
                                                                                                                                        .redAccent[500],
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        )}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedInvoice(
                                                                                                                                invoice
                                                                                                                            );
                                                                                                                            handleViewInvoicePurposeClick();
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {invoice
                                                                                                                            .purpose
                                                                                                                            .length >
                                                                                                                        30
                                                                                                                            ? `${invoice.purpose.substring(0, 30)}...`
                                                                                                                            : invoice.purpose}
                                                                                                                    </TableCell>
                                                                                                                </TableRow>
                                                                                                                {isExpandedInvoice && (
                                                                                                                    <>
                                                                                                                        <TableRow>
                                                                                                                            <TableCell
                                                                                                                                style={{
                                                                                                                                    paddingBottom: 0,
                                                                                                                                    paddingTop: 0,
                                                                                                                                }}
                                                                                                                                colSpan={
                                                                                                                                    10
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Accordion>
                                                                                                                                    <AccordionSummary
                                                                                                                                        expandIcon={
                                                                                                                                            <ExpandMoreIcon />
                                                                                                                                        }
                                                                                                                                        aria-controls="panel1a-content"
                                                                                                                                        id="panel1a-header"
                                                                                                                                        sx={{
                                                                                                                                            backgroundColor:
                                                                                                                                                colors
                                                                                                                                                    .primary[400],
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Typography>
                                                                                                                                            Documents
                                                                                                                                        </Typography>
                                                                                                                                    </AccordionSummary>
                                                                                                                                    <AccordionDetails
                                                                                                                                        sx={{
                                                                                                                                            backgroundColor:
                                                                                                                                                colors
                                                                                                                                                    .primary[400],
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Box
                                                                                                                                            // height="70vh"
                                                                                                                                            sx={{
                                                                                                                                                '& .MuiTableHead-root':
                                                                                                                                                    {
                                                                                                                                                        backgroundColor:
                                                                                                                                                            colors
                                                                                                                                                                .blueAccent[400],
                                                                                                                                                        borderBottom:
                                                                                                                                                            'none',
                                                                                                                                                    },
                                                                                                                                                '& .MuiTablePagination-root':
                                                                                                                                                    {
                                                                                                                                                        borderTop:
                                                                                                                                                            'none',
                                                                                                                                                        backgroundColor:
                                                                                                                                                            colors
                                                                                                                                                                .blueAccent[400],
                                                                                                                                                    },
                                                                                                                                                '& .MuiCheckbox-root':
                                                                                                                                                    {
                                                                                                                                                        color: `${colors.greenAccent[200]} !important`,
                                                                                                                                                    },
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <TableContainer
                                                                                                                                                sx={{
                                                                                                                                                    height: '100%',
                                                                                                                                                    backgroundColor:
                                                                                                                                                        colors
                                                                                                                                                            .primary[400],
                                                                                                                                                    borderRadius:
                                                                                                                                                        '5px',
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Table>
                                                                                                                                                    <TableHead>
                                                                                                                                                        <TableRow>
                                                                                                                                                            {/* <TableCell></TableCell> New cell for the arrow icon */}
                                                                                                                                                            <TableCell padding="checkbox">
                                                                                                                                                                <Checkbox
                                                                                                                                                                    color="primary"
                                                                                                                                                                    indeterminate={
                                                                                                                                                                        someDocumentsSelectedInCurrentWarranty(
                                                                                                                                                                            row,
                                                                                                                                                                            invoice
                                                                                                                                                                        ) &&
                                                                                                                                                                        !allDocumentsSelectedInCurrentWarranty(
                                                                                                                                                                            row,
                                                                                                                                                                            invoice
                                                                                                                                                                        )
                                                                                                                                                                    }
                                                                                                                                                                    checked={allDocumentsSelectedInCurrentWarranty(
                                                                                                                                                                        row,
                                                                                                                                                                        invoice
                                                                                                                                                                    )}
                                                                                                                                                                    onChange={(
                                                                                                                                                                        event
                                                                                                                                                                    ) =>
                                                                                                                                                                        handleSelectAllClickDocument(
                                                                                                                                                                            event,
                                                                                                                                                                            row,
                                                                                                                                                                            invoice
                                                                                                                                                                        )
                                                                                                                                                                    }
                                                                                                                                                                    inputProps={{
                                                                                                                                                                        'aria-label':
                                                                                                                                                                            'select all documents',
                                                                                                                                                                    }}
                                                                                                                                                                />
                                                                                                                                                            </TableCell>
                                                                                                                                                            {docColumns.map((column) => (
                                                                                                                                                                <TableHeaderCell
                                                                                                                                                                    key={column.column}
                                                                                                                                                                    columns={docColumns}
                                                                                                                                                                    column={column.column}
                                                                                                                                                                    columnHeaderName={column.columnHeaderName}
                                                                                                                                                                    order={docOrder}
                                                                                                                                                                    orderBy={docOrderBy}
                                                                                                                                                                    handleRequestSort={handleDocRequestSort}
                                                                                                                                                                    handleFilter={handleDocFilter}
                                                                                                                                                                    sortable={column.sortable}
                                                                                                                                                                    center={column.center}
                                                                                                                                                                />
                                                                                                                                                            ))}
                                                                                                                                                        </TableRow>
                                                                                                                                                    </TableHead>
                                                                                                                                                    <TableBody>
                                                                                                                                                        {filteredRow(invoice.documents, docFilterOptions, docOrder, docOrderBy)
                                                                                                                                                            .slice(
                                                                                                                                                                pageDocument *
                                                                                                                                                                    rowsPerPageDocument,
                                                                                                                                                                pageDocument *
                                                                                                                                                                    rowsPerPageDocument +
                                                                                                                                                                    rowsPerPageDocument
                                                                                                                                                            )
                                                                                                                                                            .map(
                                                                                                                                                                (
                                                                                                                                                                    document
                                                                                                                                                                ) => {
                                                                                                                                                                    const isItemSelected =
                                                                                                                                                                        isDocumentSelected(
                                                                                                                                                                            document.id,
                                                                                                                                                                            row.id,
                                                                                                                                                                            invoice.id
                                                                                                                                                                        );
                                                                                                                                                                    // const isExpanded = expandedRows[picture.id];
                                                                                                                                                                    return (
                                                                                                                                                                        <React.Fragment
                                                                                                                                                                            key={
                                                                                                                                                                                document.id
                                                                                                                                                                            }
                                                                                                                                                                        >
                                                                                                                                                                            <TableRow
                                                                                                                                                                                hover
                                                                                                                                                                                role="checkbox"
                                                                                                                                                                                aria-checked={
                                                                                                                                                                                    isItemSelected
                                                                                                                                                                                }
                                                                                                                                                                                tabIndex={
                                                                                                                                                                                    -1
                                                                                                                                                                                }
                                                                                                                                                                                selected={
                                                                                                                                                                                    isItemSelected
                                                                                                                                                                                }
                                                                                                                                                                                sx={{
                                                                                                                                                                                    cursor: 'pointer',
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                {/* <TableCell onClick={() => toggleRowExpansion(picture.id)}>
                                                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                      </TableCell> */}
                                                                                                                                                                                <TableCell padding="checkbox">
                                                                                                                                                                                    <Checkbox
                                                                                                                                                                                        color="primary"
                                                                                                                                                                                        checked={
                                                                                                                                                                                            isItemSelected
                                                                                                                                                                                        }
                                                                                                                                                                                        onChange={(
                                                                                                                                                                                            event
                                                                                                                                                                                        ) =>
                                                                                                                                                                                            handleCheckboxChangeDocument(
                                                                                                                                                                                                event,
                                                                                                                                                                                                document.id,
                                                                                                                                                                                                invoice.id,
                                                                                                                                                                                                row.id
                                                                                                                                                                                            )
                                                                                                                                                                                        }
                                                                                                                                                                                        inputProps={{
                                                                                                                                                                                            'aria-labelledby': `checkbox-${document.id}`,
                                                                                                                                                                                        }}
                                                                                                                                                                                    />
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell>
                                                                                                                                                                                    {
                                                                                                                                                                                        document.id
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell>
                                                                                                                                                                                    {
                                                                                                                                                                                        document.title
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell
                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                        setSelectedDocument(
                                                                                                                                                                                            document
                                                                                                                                                                                        );
                                                                                                                                                                                        handleViewDocumentDescriptionClick();
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    {document
                                                                                                                                                                                        .description
                                                                                                                                                                                        .length >
                                                                                                                                                                                    30
                                                                                                                                                                                        ? `${document.description.substring(0, 30)}...`
                                                                                                                                                                                        : document.description}
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell align="center">
                                                                                                                                                                                    {
                                                                                                                                                                                        <Button
                                                                                                                                                                                            variant="contained"
                                                                                                                                                                                            sx={{
                                                                                                                                                                                                color: 'white',
                                                                                                                                                                                                backgroundColor:
                                                                                                                                                                                                    colors
                                                                                                                                                                                                        .greenAccent[500],
                                                                                                                                                                                                '&:hover':
                                                                                                                                                                                                    {
                                                                                                                                                                                                        backgroundColor:
                                                                                                                                                                                                            colors
                                                                                                                                                                                                                .greenAccent[700],
                                                                                                                                                                                                    },
                                                                                                                                                                                            }}
                                                                                                                                                                                            startIcon={
                                                                                                                                                                                                <PictureAsPdfOutlinedIcon />
                                                                                                                                                                                            }
                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                setSelectedDocument(
                                                                                                                                                                                                    document
                                                                                                                                                                                                );
                                                                                                                                                                                                setSelectedInvoice(
                                                                                                                                                                                                    invoice
                                                                                                                                                                                                );
                                                                                                                                                                                                setSelectedWarranty(
                                                                                                                                                                                                    row
                                                                                                                                                                                                );
                                                                                                                                                                                                handleViewDocumentClick();
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            View
                                                                                                                                                                                        </Button>
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                                <TableCell align="center">
                                                                                                                                                                                    {
                                                                                                                                                                                        <Button
                                                                                                                                                                                            variant="outlined"
                                                                                                                                                                                            sx={{
                                                                                                                                                                                                color: colors
                                                                                                                                                                                                    .grey[100],
                                                                                                                                                                                                borderColor:
                                                                                                                                                                                                    colors
                                                                                                                                                                                                        .grey[100],
                                                                                                                                                                                            }}
                                                                                                                                                                                            startIcon={
                                                                                                                                                                                                <EditOutlinedIcon />
                                                                                                                                                                                            }
                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                setSelectedWarranty(
                                                                                                                                                                                                    row
                                                                                                                                                                                                );
                                                                                                                                                                                                setSelectedDocument(
                                                                                                                                                                                                    document
                                                                                                                                                                                                );
                                                                                                                                                                                                setSelectedInvoice(
                                                                                                                                                                                                    invoice
                                                                                                                                                                                                );
                                                                                                                                                                                                handleEditDocumentClick();
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            Edit
                                                                                                                                                                                        </Button>
                                                                                                                                                                                    }
                                                                                                                                                                                </TableCell>
                                                                                                                                                                            </TableRow>
                                                                                                                                                                        </React.Fragment>
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                            )}
                                                                                                                                                    </TableBody>
                                                                                                                                                </Table>
                                                                                                                                            </TableContainer>
                                                                                                                                            <CustomFooter
                                                                                                                                                rowCount={
                                                                                                                                                    invoice
                                                                                                                                                        .documents
                                                                                                                                                        .length
                                                                                                                                                }
                                                                                                                                                page={
                                                                                                                                                    pageDocument
                                                                                                                                                }
                                                                                                                                                rowsPerPage={
                                                                                                                                                    rowsPerPageDocument
                                                                                                                                                }
                                                                                                                                                onPageChange={
                                                                                                                                                    handleChangePageDocument
                                                                                                                                                }
                                                                                                                                                onRowsPerPageChange={
                                                                                                                                                    handleChangeRowsPerPageDocument
                                                                                                                                                }
                                                                                                                                                backgroundColor={
                                                                                                                                                    colors
                                                                                                                                                        .blueAccent[400]
                                                                                                                                                }
                                                                                                                                                onActionSelect={(action) => handleSelectActionDocument(action, row, invoice)}
                                                                                                                                                row={row} // Pass row as prop if available
                                                                                                                                                invoice={invoice} // Pass invoice as prop if available
                                                                                                                                                isOuterTable={false}
                                                                                                                                                buttons={[
                                                                                                                                                    {
                                                                                                                                                        label: 'Add',
                                                                                                                                                        onClick:
                                                                                                                                                            () => {
                                                                                                                                                                setSelectedWarranty(
                                                                                                                                                                    row
                                                                                                                                                                );
                                                                                                                                                                setSelectedInvoice(
                                                                                                                                                                    invoice
                                                                                                                                                                );
                                                                                                                                                                setAddAttachDocumentModalOpen(
                                                                                                                                                                    true
                                                                                                                                                                );
                                                                                                                                                            },
                                                                                                                                                        styles: {
                                                                                                                                                            color: 'white',
                                                                                                                                                            backgroundColor:
                                                                                                                                                                colors
                                                                                                                                                                    .greenAccent[500],
                                                                                                                                                            '&:hover':
                                                                                                                                                                {
                                                                                                                                                                    backgroundColor:
                                                                                                                                                                        colors
                                                                                                                                                                            .greenAccent[700],
                                                                                                                                                                },
                                                                                                                                                            mr: 1,
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        label: 'Delete',
                                                                                                                                                        onClick:
                                                                                                                                                            () => {
                                                                                                                                                                setSelectedWarranty(
                                                                                                                                                                    row
                                                                                                                                                                );
                                                                                                                                                                setSelectedInvoice(
                                                                                                                                                                    invoice
                                                                                                                                                                );
                                                                                                                                                                setDeleteDocumentModalOpen(
                                                                                                                                                                    true
                                                                                                                                                                );
                                                                                                                                                            },
                                                                                                                                                        styles: {
                                                                                                                                                            color: 'white',
                                                                                                                                                            backgroundColor:
                                                                                                                                                                colors
                                                                                                                                                                    .redAccent[500],
                                                                                                                                                            '&:hover':
                                                                                                                                                                {
                                                                                                                                                                    backgroundColor:
                                                                                                                                                                        colors
                                                                                                                                                                            .redAccent[700],
                                                                                                                                                                },
                                                                                                                                                            mr: 1,
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                ]}
                                                                                                                                            />
                                                                                                                                        </Box>
                                                                                                                                    </AccordionDetails>
                                                                                                                                </Accordion>
                                                                                                                            </TableCell>
                                                                                                                        </TableRow>
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </React.Fragment>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                                <CustomFooter
                                                                                    rowCount={
                                                                                        row
                                                                                            .invoices
                                                                                            .length
                                                                                    }
                                                                                    page={
                                                                                        pageInvoice
                                                                                    }
                                                                                    rowsPerPage={
                                                                                        rowsPerPageInvoice
                                                                                    }
                                                                                    onPageChange={
                                                                                        handleChangePageInvoice
                                                                                    }
                                                                                    onRowsPerPageChange={
                                                                                        handleChangeRowsPerPageInvoice
                                                                                    }
                                                                                    backgroundColor={
                                                                                        colors
                                                                                            .blueAccent[500]
                                                                                    }
                                                                                    onActionSelect={(action) => handleSelectActionInvoice(action, row)}
                                                                                    row={row} // Pass row as prop if available
                                                                                    isOuterTable={false}
                                                                                    buttons={[
                                                                                        {
                                                                                            label: 'Add',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedWarranty(
                                                                                                        row
                                                                                                    );
                                                                                                    setAddInvoiceModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .greenAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .greenAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            label: 'Delete',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedWarranty(
                                                                                                        row
                                                                                                    );
                                                                                                    setDeleteInvoiceModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .redAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .redAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            label: 'Set Invoice Date',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedWarranty(
                                                                                                        row
                                                                                                    );
                                                                                                    setInvoiceDateModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .greenAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .greenAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            label: 'Set Paid Date',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedWarranty(
                                                                                                        row
                                                                                                    );
                                                                                                    setPaidDateModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .greenAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .greenAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            label: 'Set Paid',
                                                                                            onClick:
                                                                                                () => {
                                                                                                    setSelectedWarranty(
                                                                                                        row
                                                                                                    );
                                                                                                    setPaidModalOpen(
                                                                                                        true
                                                                                                    );
                                                                                                },
                                                                                            styles: {
                                                                                                color: 'white',
                                                                                                backgroundColor:
                                                                                                    colors
                                                                                                        .greenAccent[500],
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            colors
                                                                                                                .greenAccent[700],
                                                                                                    },
                                                                                                mr: 1,
                                                                                            },
                                                                                        },
                                                                                        // Add other buttons as needed
                                                                                    ]}
                                                                                />
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomFooter
                            rowCount={dropdownData.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            backgroundColor={colors.blueAccent[700]}
                            onActionSelect={handleSelectAction}
                            isOuterTable={true}
                            buttons={[
                                {
                                    label: 'Add',
                                    onClick: () =>
                                        setAddModalOpen(true),
                                    styles: {
                                        color: 'white',
                                        backgroundColor:
                                            colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.greenAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                                {
                                    label: 'Delete',
                                    onClick: () =>
                                        setDeleteWarrantyModalOpen(true),
                                    styles: {
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.redAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                                {
                                    label: 'Set Reported Date',
                                    onClick: () =>
                                        setReportedDateModalOpen(true),
                                    styles: {
                                        color: 'white',
                                        backgroundColor:
                                            colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.greenAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                                {
                                    label: 'Sched. Fix',
                                    onClick: () => setFixModalOpen(true),
                                    styles: {
                                        color: 'white',
                                        backgroundColor:
                                            colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.greenAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                                {
                                    label: 'Set Completion Date',
                                    onClick: () =>
                                        setCompleteDateModalOpen(true),
                                    styles: {
                                        color: 'white',
                                        backgroundColor:
                                            colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.greenAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                                {
                                    label: 'Set Completed',
                                    onClick: () => setCompletedModalOpen(true),
                                    styles: {
                                        color: 'white',
                                        backgroundColor:
                                            colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.greenAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                                {
                                    label: 'Reset',
                                    onClick: () => resetValues(),
                                    styles: {
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor:
                                                colors.redAccent[700],
                                        },
                                        mr: 1,
                                    },
                                },
                            ]}
                        />
                        <ScheduleFixModal
                            isFixModalOpen={isFixModalOpen}
                            setFixModalOpen={setFixModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedWarranties={selectedRows}
                            setCheckedWarranties={setSelectedRows}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <ReportedDateModal
                            isReportedDateModalOpen={isReportedDateModalOpen}
                            setReportedDateModalOpen={setReportedDateModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedWarranties={selectedRows}
                            setCheckedWarranties={setSelectedRows}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <CompletedDateModal
                            isCompleteDateModalOpen={isCompleteDateModalOpen}
                            setCompleteDateModalOpen={setCompleteDateModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedWarranties={selectedRows}
                            setCheckedWarranties={setSelectedRows}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <CompletedModal
                            isCompletedModalOpen={isCompletedModalOpen}
                            setCompletedModalOpen={setCompletedModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedWarranties={selectedRows}
                            setCheckedWarranties={setSelectedRows}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <ViewIssueModal
                            isViewIssueModalOpen={isViewIssueModalOpen}
                            setViewIssueModalOpen={setViewIssueModalOpen}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <ViewPictureModal
                            isViewPictureModalOpen={isViewPictureModalOpen}
                            setViewPictureModalOpen={setViewPictureModalOpen}
                            selectedPicture={selectedPicture}
                            setSelectedPicture={setSelectedPicture}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <ViewDescriptionModal
                            isViewDescriptionModalOpen={
                                isViewDescriptionModalOpen
                            }
                            setViewDescriptionModalOpen={
                                setViewDescriptionModalOpen
                            }
                            selectedPicture={selectedPicture}
                            setSelectedPicture={setSelectedPicture}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeletePictureModal
                            isDeletePictureModalOpen={isDeletePictureModalOpen}
                            setDeletePictureModalOpen={
                                setDeletePictureModalOpen
                            }
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            selectedRowsPicture={selectedPicturesPerWarranty}
                            setSelectedRowsPicture={
                                setSelectedPicturesPerWarranty
                            }
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <ViewPurposeModal
                            isViewPurposeModalOpen={isViewPurposeModalOpen}
                            setViewPurposeModalOpen={setViewPurposeModalOpen}
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeleteInvoiceModal
                            isDeleteInvoiceModalOpen={isDeleteInvoiceModalOpen}
                            setDeleteInvoiceModalOpen={
                                setDeleteInvoiceModalOpen
                            }
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            selectedRowsInvoice={selectedInvoicesPerWarranty}
                            setSelectedRowsInvoice={
                                setSelectedInvoicesPerWarranty
                            }
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <ViewDocDescriptionModal
                            isViewDocDescriptionModalOpen={
                                isViewDocDescriptionModalOpen
                            }
                            setViewDocDescriptionModalOpen={
                                setViewDocDescriptionModalOpen
                            }
                            selectedDocument={selectedDocument}
                            setSelectedDocument={setSelectedDocument}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeleteDocumentModal
                            isDeleteDocumentModalOpen={
                                isDeleteDocumentModalOpen
                            }
                            setDeleteDocumentModalOpen={
                                setDeleteDocumentModalOpen
                            }
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            selectedRowsDocument={selectedDocumentsPerWarranty}
                            setSelectedRowsDocument={
                                setSelectedDocumentsPerWarranty
                            }
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeleteWarrantyModal
                            isDeleteWarrantyModalOpen={
                                isDeleteWarrantyModalOpen
                            }
                            setDeleteWarrantyModalOpen={
                                setDeleteWarrantyModalOpen
                            }
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <InvoiceDateModal
                            isInvoiceDateModalOpen={isInvoiceDateModalOpen}
                            setInvoiceDateModalOpen={setInvoiceDateModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            selectedInvoicesPerWarranty={
                                selectedInvoicesPerWarranty
                            }
                            setSelectedInvoicesPerWarranty={
                                setSelectedInvoicesPerWarranty
                            }
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <PaidDateModal
                            isPaidDateModalOpen={isPaidDateModalOpen}
                            setPaidDateModalOpen={setPaidDateModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            selectedInvoicesPerWarranty={
                                selectedInvoicesPerWarranty
                            }
                            setSelectedInvoicesPerWarranty={
                                setSelectedInvoicesPerWarranty
                            }
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <ViewDocumentModal
                            isViewDocumentModalOpen={isViewDocumentModalOpen}
                            setViewDocumentModalOpen={setViewDocumentModalOpen}
                            selectedDocument={selectedDocument}
                            setSelectedDocument={setSelectedDocument}
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <PaidModal
                            isPaidModalOpen={isPaidModalOpen}
                            setPaidModalOpen={setPaidModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            selectedInvoicesPerWarranty={
                                selectedInvoicesPerWarranty
                            }
                            setSelectedInvoicesPerWarranty={
                                setSelectedInvoicesPerWarranty
                            }
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <EditInvoiceModal
                            isEditInvoiceModalOpen={isEditInvoiceModalOpen}
                            setEditInvoiceModalOpen={setEditInvoiceModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <AddInvoiceModal
                            isAddInvoiceModalOpen={isAddInvoiceModalOpen}
                            setAddInvoiceModalOpen={setAddInvoiceModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                        />
                        <EditPictureModal
                            isEditPictureModalOpen={isEditPictureModalOpen}
                            setEditPictureModalOpen={setEditPictureModalOpen}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            selectedPicture={selectedPicture}
                            setSelectedPicture={setSelectedPicture}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <EditDocumentModal
                            isEditDocumentModalOpen={isEditDocumentModalOpen}
                            setEditDocumentModalOpen={setEditDocumentModalOpen}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            selectedDocument={selectedDocument}
                            setSelectedDocument={setSelectedDocument}
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <AddAttachPictureModal
                            isAddAttachPictureModalOpen={
                                isAddAttachPictureModalOpen
                            }
                            setAddAttachPictureModalOpen={
                                setAddAttachPictureModalOpen
                            }
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <AddAttachDocumentModal
                            isAddAttachDocumentModalOpen={
                                isAddAttachDocumentModalOpen
                            }
                            setAddAttachDocumentModalOpen={
                                setAddAttachDocumentModalOpen
                            }
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <EditWarrantyModal
                            isEditModalOpen={isEditModalOpen}
                            setEditModalOpen={setEditModalOpen}
                            selectedWarranty={selectedWarranty}
                            setSelectedWarranty={setSelectedWarranty}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <AddWarrantyModal
                            isAddModalOpen={isAddModalOpen}
                            setAddModalOpen={setAddModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Warranty;
